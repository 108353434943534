<template>
  <div class="filter-wrap">
    <form action="" class="filter-form">
      <div class="clear-filter">
        <button
          type="button"
          @click.prevent="clearFilter"
          @keydown.enter="clearFilter"
          tabindex="0"
        >
          Clear filter
        </button>
      </div>
      <div class="form-group">
        <label for="search">
          <input
            type="text"
            placeholder="Search your product here"
            id="search"
            v-model="searchKeyword"
          />
          <span class="label-name">Product Search</span>
        </label>
      </div>
      <div class="advanced-search">
        <p
          class="advanced-click"
          :class="{ active: isActive }"
          @click="toggleActive"
          @keydown.enter="toggleActive"
          tabindex="0"
        >
          {{ showText ? "Click here to advanced search " : "Click here to product search" }}
        </p>
        <div class="advanced-search-wrap" :class="{ active: isActive }">
          <div class="inner">
            <div class="custom-select">
              <div
                class="select-box"
                @click="toggleDropdown(4)"
                @keydown.enter="toggleDropdown(4)"
                tabindex="0"
              >
                <div :class="['selected-item', { 'list-selected': selectedOption5.length > 0 }]">
                  {{
                    selectedOption5?.length > 0
                      ? selectedOption5.map((op) => op.name).join(", ")
                      : "Select your collection"
                  }}
                </div>
                <span class="label-name">Collections</span>
                <div class="arrow" :class="{ open: isDropdownOpen[4] }"></div>
                <div
                  v-if="selectedOption5.length > 0"
                  class="close-button"
                  @click="clearSelectionFive(4)"
                  @keydown.enter="clearSelectionFive(4)"
                  tabindex="0"
                >
                  X
                </div>
              </div>
              <div class="dropdown-search" v-show="isDropdownOpen[4]">
                <label for="Collections-search">
                  <input
                    type="text"
                    placeholder="Search Collections"
                    v-model="searchText"
                    id="Collections-search"
                  />
                </label>
              </div>
              <ul class="dropdown-menu" v-show="isDropdownOpen[4]">
                <li v-if="!selectedOption5" class="placeholder">Select your collection</li>
                <li
                  v-for="collection in filteredCollections"
                  :key="collection.id"
                  :id="collection.id"
                  :navId="collection.navId"
                  @click="selectOptionFour(4, collection)"
                  @keydown.enter="selectOptionFour(4, collection)"
                  tabindex="0"
                >
                  {{ collection.name }}
                </li>
              </ul>
            </div>

            <div class="custom-select">
              <div
                class="select-box"
                @click="toggleDropdown(5)"
                @keydown.enter="toggleDropdown(5)"
                tabindex="0"
              >
                <div :class="['selected-item', { 'list-selected': selectedOption6.length > 0 }]">
                  {{
                    selectedOption6?.length > 0
                      ? selectedOption6.map((op) => op.name).join(", ")
                      : "Select your groups"
                  }}
                </div>
                <span class="label-name">Groups</span>
                <div class="arrow" :class="{ open: isDropdownOpen[5] }"></div>
                <div
                  v-if="selectedOption6.length > 0"
                  class="close-button"
                  @click="clearSelectionSix(5)"
                  @keydown.enter="clearSelectionSix(5)"
                  tabindex="0"
                >
                  X
                </div>
              </div>
              <div class="dropdown-search" v-show="isDropdownOpen[5]">
                <label for="Groups-search">
                  <input
                    type="text"
                    placeholder="Search Groups"
                    v-model="searchText"
                    id="Groups-search"
                  />
                </label>
              </div>
              <ul class="dropdown-menu" v-show="isDropdownOpen[5]">
                <li v-if="!selectedOption6" class="placeholder">Select your groups</li>
                <li
                  v-for="group in filteredGroups"
                  :key="group.id"
                  :id="group.id"
                  :navId="group.navId"
                  @click="selectOptionFive(5, group)"
                  @keydown.enter="selectOptionFive(5, group)"
                  tabindex="0"
                >
                  {{ group.name }}
                </li>
              </ul>
            </div>

            <div class="custom-select">
              <div
                class="select-box"
                @click="toggleDropdown(6)"
                @keydown.enter="toggleDropdown(6)"
                tabindex="0"
              >
                <div :class="['selected-item', { 'list-selected': selectedOption7.length > 0 }]">
                  {{
                    selectedOption7?.length > 0
                      ? selectedOption7.map((op) => op.name).join(", ")
                      : "Select your sub groups"
                  }}
                </div>
                <span class="label-name">Sub Groups</span>
                <div class="arrow" :class="{ open: isDropdownOpen[6] }"></div>
                <div
                  v-if="selectedOption7.length > 0"
                  class="close-button"
                  @click="clearSelectionSeven(6)"
                  @keydown.enter="clearSelectionSeven(6)"
                  tabindex="0"
                >
                  X
                </div>
              </div>
              <div class="dropdown-search" v-show="isDropdownOpen[6]">
                <label for="subGroups-search">
                  <input
                    type="text"
                    placeholder="Search subGroups"
                    v-model="searchText"
                    id="subGroups-search"
                  />
                </label>
              </div>
              <ul class="dropdown-menu" v-show="isDropdownOpen[6]">
                <li v-if="!selectedOption7" class="placeholder">Select your sub groups</li>
                <li
                  v-for="subGroup in filteredSubGroups"
                  :key="subGroup.id"
                  :id="subGroup.id"
                  :navId="subGroup.navId"
                  @click="selectOptionSix(6, subGroup)"
                  @keydown.enter="selectOptionSix(6, subGroup)"
                  tabindex="0"
                >
                  {{ subGroup.name }}
                </li>
              </ul>
            </div>

            <div class="custom-select">
              <div
                class="select-box"
                @click="toggleDropdown(7)"
                @keydown.enter="toggleDropdown(7)"
                tabindex="0"
              >
                <div :class="['selected-item', { 'list-selected': selectedOption8.length > 0 }]">
                  {{
                    selectedOption8?.length > 0
                      ? selectedOption8.map((op) => op.name).join(", ")
                      : "Select your categories"
                  }}
                </div>
                <span class="label-name">Categories</span>
                <div class="arrow" :class="{ open: isDropdownOpen[7] }"></div>
                <div
                  v-if="selectedOption8.length > 0"
                  class="close-button"
                  @click="clearSelectionEight(7)"
                  @keydown.enter="clearSelectionEight(7)"
                  tabindex="0"
                >
                  X
                </div>
              </div>
              <div class="dropdown-search" v-show="isDropdownOpen[7]">
                <label for="Categories-search">
                  <input
                    type="text"
                    placeholder="Search Categories"
                    v-model="searchText"
                    id="Categories-search"
                  />
                </label>
              </div>
              <ul class="dropdown-menu" v-show="isDropdownOpen[7]">
                <li v-if="!selectedOption8" class="placeholder">Select your categories</li>
                <li
                  v-for="category in filteredCategories"
                  :key="category.id"
                  :id="category.id"
                  :navId="category.navId"
                  @click="selectOptionSeven(7, category)"
                  @keydown.enter="selectOptionSeven(7, category)"
                  tabindex="0"
                >
                  {{ category.name }}
                </li>
              </ul>
            </div>

            <div class="custom-select">
              <div
                class="select-box"
                @click="toggleDropdown(8)"
                @keydown.enter="toggleDropdown(8)"
                tabindex="0"
              >
                <div :class="['selected-item', { 'list-selected': selectedOption9.length > 0 }]">
                  {{
                    selectedOption9?.length > 0
                      ? selectedOption9.map((op) => op.name).join(", ")
                      : "Select your configurator groups"
                  }}
                </div>
                <span class="label-name">Configurator Groups</span>
                <div class="arrow" :class="{ open: isDropdownOpen[8] }"></div>
                <div
                  v-if="selectedOption9.length > 0"
                  class="close-button"
                  @click="clearSelectionNine(8)"
                  @keydown.enter="clearSelectionNine(8)"
                  tabindex="0"
                >
                  X
                </div>
              </div>
              <div class="dropdown-search" v-show="isDropdownOpen[8]">
                <label for="config_groups-search">
                  <input
                    type="text"
                    placeholder="Search config groups"
                    v-model="searchText"
                    id="config_groups-search"
                  />
                </label>
              </div>
              <ul class="dropdown-menu" v-show="isDropdownOpen[8]">
                <li v-if="!selectedOption9" class="placeholder">Select your configurator groups</li>
                <li
                  v-for="configGroup in filteredConfiguratorGroups"
                  :key="configGroup.id"
                  :id="configGroup.id"
                  :navId="configGroup.navId"
                  @click="selectOptionEight(8, configGroup)"
                  @keydown.enter="selectOptionEight(8, configGroup)"
                  tabindex="0"
                >
                  {{ configGroup.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="product-search">
        <div>
          <!--  -->
          <div class="custom-select">
            <div
              class="select-box"
              @click="toggleDropdown(0)"
              @keydown.enter="toggleDropdown(0)"
              tabindex="0"
            >
              <div :class="['selected-item', { 'list-selected': selectedOption1.length > 0 }]">
                {{
                  selectedOption1?.length > 0
                    ? selectedOption1.map((op) => op.name).join(", ")
                    : "Select your upholstery here"
                }}
              </div>
              <span class="label-name">Upholstery</span>
              <div class="arrow" :class="{ open: isDropdownOpen[0] }"></div>
              <div
                v-if="selectedOption1.length > 0"
                class="close-button"
                @click="clearSelectionOne(0)"
                @keydown.enter="clearSelectionOne(0)"
                tabindex="0"
              >
                X
              </div>
            </div>
            <div class="dropdown-search" v-show="isDropdownOpen[0]">
              <label for="up-search">
                <input
                  type="text"
                  placeholder="Search upholstery"
                  v-model="searchText"
                  id="up-search"
                />
              </label>
            </div>
            <ul class="dropdown-menu" v-show="isDropdownOpen[0]">
              <li v-if="!selectedOption1?.length > 0" class="placeholder">
                Select your upholstery here
              </li>
              <li
                v-for="upholstery in filteredUpholsteries"
                :key="upholstery.id"
                :id="upholstery.id"
                @click="selectOption(0, upholstery)"
                @keydown.enter="selectOption(0, upholstery)"
                tabindex="0"
              >
                {{ upholstery.name }}
              </li>
            </ul>
          </div>

          <!--  -->

          <div class="custom-select">
            <div
              class="select-box"
              @click="toggleDropdown(1)"
              @keydown.enter="toggleDropdown(1)"
              tabindex="0"
            >
              <div :class="['selected-item', { 'list-selected': selectedOption2.length > 0 }]">
                {{
                  selectedOption2?.length > 0
                    ? selectedOption2.map((op) => op.name).join(", ")
                    : "Select your leg colour here"
                }}
              </div>
              <span class="label-name">Legs</span>
              <div class="arrow" :class="{ open: isDropdownOpen[1] }"></div>
              <div
                v-if="selectedOption2.length > 0"
                class="close-button"
                @click="clearSelectionTwo(1)"
                @keydown.enter="clearSelectionTwo(1)"
                tabindex="0"
              >
                X
              </div>
            </div>
            <div class="dropdown-search" v-show="isDropdownOpen[1]">
              <label for="leg-search">
                <input
                  type="text"
                  placeholder="Search leg colour"
                  v-model="searchText"
                  id="leg-search"
                />
              </label>
            </div>
            <ul class="dropdown-menu" v-show="isDropdownOpen[1]">
              <li v-if="!selectedOption2?.length > 0" class="placeholder">
                Select your leg colour here
              </li>
              <li
                v-for="leg in filteredLegs"
                :key="leg.id"
                :id="leg.id"
                :navId="leg.navId"
                @click="selectOptionOne(1, leg)"
                @keydown.enter="selectOptionOne(1, leg)"
                tabindex="0"
              >
                {{ leg.name }}
              </li>
            </ul>
          </div>

          <!--  -->

          <div class="custom-select">
            <div
              class="select-box"
              @click="toggleDropdown(2)"
              @keydown.enter="toggleDropdown(2)"
              tabindex="0"
            >
              <div :class="['selected-item', { 'list-selected': selectedOption3.length > 0 }]">
                {{
                  selectedOption3?.length > 0
                    ? selectedOption3.map((op) => op.name).join(", ")
                    : "Select your piping & cushions here"
                }}
              </div>
              <span class="label-name">Piping & Cushions</span>
              <div class="arrow" :class="{ open: isDropdownOpen[2] }"></div>
              <div
                v-if="selectedOption3.length > 0"
                class="close-button"
                @click="clearSelectionThree(2)"
                @keydown.enter="clearSelectionThree(2)"
                tabindex="0"
              >
                X
              </div>
            </div>
            <div class="dropdown-search" v-show="isDropdownOpen[2]">
              <label for="contUpholstery-search">
                <input
                  type="text"
                  placeholder="Search contUpholstery"
                  v-model="searchText"
                  id="contUpholstery-search"
                />
              </label>
            </div>
            <ul class="dropdown-menu" v-show="isDropdownOpen[2]">
              <li v-if="!selectedOption3" class="placeholder">
                Select your piping & cushions here
              </li>
              <li
                v-for="contUpholstery in filteredContUpholsteries"
                :key="contUpholstery.id"
                :id="contUpholstery.id"
                :navId="contUpholstery.navId"
                @click="selectOptionTwo(2, contUpholstery)"
                @keydown.enter="selectOptionTwo(2, contUpholstery)"
                tabindex="0"
              >
                {{ contUpholstery.name }}
              </li>
            </ul>
          </div>

          <!--  -->

          <div class="custom-select">
            <div
              class="select-box"
              @click="toggleDropdown(3)"
              @keydown.enter="toggleDropdown(3)"
              tabindex="0"
            >
              <div :class="['selected-item', { 'list-selected': selectedOption4.length > 0 }]">
                {{
                  selectedOption4?.length > 0
                    ? selectedOption4.map((op) => op.name).join(", ")
                    : "Select your shell colour here"
                }}
              </div>
              <span class="label-name">Shell Colour</span>
              <div class="arrow" :class="{ open: isDropdownOpen[3] }"></div>
              <div
                v-if="selectedOption4.length > 0"
                class="close-button"
                @click="clearSelectionFour(3)"
                @keydown.enter="clearSelectionFour(3)"
                tabindex="0"
              >
                X
              </div>
            </div>
            <div class="dropdown-search" v-show="isDropdownOpen[3]">
              <label for="shellColors-search">
                <input
                  type="text"
                  placeholder="Search shellColors"
                  v-model="searchText"
                  id="shellColors-search"
                />
              </label>
            </div>
            <ul class="dropdown-menu" v-show="isDropdownOpen[3]">
              <li v-if="!selectedOption4" class="placeholder">Select your shell colour here</li>
              <li
                v-for="shellColor in filteredshellColors"
                :key="shellColor.id"
                :id="shellColor.id"
                :navId="shellColor.navId"
                @click="selectOptionThree(3, shellColor)"
                @keydown.enter="selectOptionThree(3, shellColor)"
                tabindex="0"
              >
                {{ shellColor.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="btn-wrap">
        <button
          class="searchClassBtn"
          :class="
            searchKeyword !== '' ||
            selectedOption1.length > 0 ||
            selectedOption2.length > 0 ||
            selectedOption3.length > 0 ||
            selectedOption4.length > 0 ||
            selectedOption5.length > 0 ||
            selectedOption6.length > 0 ||
            selectedOption7.length > 0 ||
            selectedOption8.length > 0 ||
            selectedOption9.length > 0
              ? 'searchClassBtn enabled'
              : 'searchClassBtn disabled'
          "
          @click.prevent="fetchData"
          @keydown.enter="fetchData"
          tabindex="0"
        >
          Search
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Cookies from "js-cookie";
import axios from "axios";
import { API_BASE_URL } from "../ApiConfig";

// import productState from '../store/modules/ProductList';
// import { store } from 'vuex';

const API_KEY = "6548454121546548764564457464854546";
// const proxyurl = 'https://cors-anywhere.herokuapp.com/';

export default {
  name: "MultipleCustomSelects",
  setup() {
    // const options = [
    //   ['Option 1', 'Option 2', 'Option 3'],
    //   ['Choice A', 'Choice B', 'Choice C'],
    //   ['Choice A2', 'Choice B2', 'Choice C2'],
    //   ['Choice A3', 'Choice B3', 'Choice C3'],
    //   ['Choice A4', 'Choice B4', 'Choice C4'],
    //   ['Choice A5', 'Choice B5', 'Choice C5'],
    //   ['Choice A6', 'Choice B6', 'Choice C6'],
    //   ['Choice A7', 'Choice B7', 'Choice C7'],
    //   ['Choice A8', 'Choice B8', 'Choice C8'],
    // ];

    const selectedOption = ref(["", "", "", "", "", "", "", "", ""]);
    const selectedOption1 = [];
    const selectedOption2 = [];
    const selectedOption3 = [];
    const selectedOption4 = [];
    const selectedOption5 = [];
    const selectedOption6 = [];
    const selectedOption7 = [];
    const selectedOption8 = [];
    const selectedOption9 = [];
    const selectedOptionIdsArray1 = ref([]);
    const selectedOptionIdsArray2 = ref([]);
    const selectedOptionIdsArray3 = ref([]);
    const selectedOptionIdsArray4 = ref([]);
    const selectedOptionIdsArray5 = ref([]);
    const selectedOptionIdsArray6 = ref([]);
    const selectedOptionIdsArray7 = ref([]);
    const selectedOptionIdsArray8 = ref([]);
    const selectedOptionIdsArray9 = ref([]);
    const selectedOptionIds = ref(["", "", "", "", "", "", "", "", ""]);
    const isDropdownOpen = ref([false, false, false, false, false, false, false, false, false]);
    const searchText = ref("");
    let activeDropdown = null;

    function clearInput() {
      searchText.value = ""; // Set searchText to an empty string to clear the input
    }
    function toggleDropdown(index) {
      if (activeDropdown !== null && activeDropdown !== index) {
        isDropdownOpen.value[activeDropdown] = false;
      }
      isDropdownOpen.value[index] = !isDropdownOpen.value[index];
      activeDropdown = isDropdownOpen.value[index] ? index : null;

      if (isDropdownOpen.value[index]) {
        clearInput();
      }
    }

    function selectOption(index, option) {
      selectedOption.value[index] = option.name;
      selectedOptionIds.value[index] = option.id;
      isDropdownOpen.value[index] = false;

      // Check if option is already in the list
      const optionIndex = selectedOption1.findIndex((op) => option.id === op.id);

      if (optionIndex > -1) {
        selectedOption1.splice(optionIndex, 1); // Remove option if it's already in the list
      } else {
        selectedOption1.push(option); // Add option if it's not in the list
      }

      // Check if option ID is already in the array
      const optionIndex2 = selectedOptionIdsArray1.value.findIndex((op) => option.id === op);

      if (optionIndex2 > -1) {
        selectedOptionIdsArray1.value.splice(optionIndex2, 1); // Remove option ID if it's already in the array
      } else {
        selectedOptionIdsArray1.value.push(option.id); // Add option ID if it's not in the array
      }
      // console.log('selectedOptionIdsArray1', selectedOptionIdsArray1);
      // console.log(selectedOption.value[index], selectedOptionIds.value[index], option);
    }

    function selectOptionOne(index, option) {
      selectedOption.value[index] = option.name;
      selectedOptionIds.value[index] = option.id;
      isDropdownOpen.value[index] = false;
      // Check if option is already in the list
      const optionIndex = selectedOption2.findIndex((op) => option.id === op.id);

      if (optionIndex > -1) {
        selectedOption2.splice(optionIndex, 1); // Remove option if it's already in the list
      } else {
        selectedOption2.push(option); // Add option if it's not in the list
      }

      // Check if option ID is already in the array
      const optionIndex2 = selectedOptionIdsArray2.value.findIndex((op) => option.id === op);

      if (optionIndex2 > -1) {
        selectedOptionIdsArray2.value.splice(optionIndex2, 1); // Remove option ID if it's already in the array
      } else {
        selectedOptionIdsArray2.value.push(option.id); // Add option ID if it's not in the array
      }
      // console.log('selectedOptionIdsArray2', selectedOptionIdsArray2);
    }

    function selectOptionTwo(index, option) {
      selectedOption.value[index] = option.name;
      selectedOptionIds.value[index] = option.id;
      isDropdownOpen.value[index] = false;
      // Check if option is already in the list
      const optionIndex = selectedOption3.findIndex((op) => option.id === op.id);

      if (optionIndex > -1) {
        selectedOption3.splice(optionIndex, 1); // Remove option if it's already in the list
      } else {
        selectedOption3.push(option); // Add option if it's not in the list
      }

      // Check if option ID is already in the array
      const optionIndex2 = selectedOptionIdsArray3.value.findIndex((op) => option.id === op);

      if (optionIndex2 > -1) {
        selectedOptionIdsArray3.value.splice(optionIndex2, 1); // Remove option ID if it's already in the array
      } else {
        selectedOptionIdsArray3.value.push(option.id); // Add option ID if it's not in the array
      }
    }

    function selectOptionThree(index, option) {
      selectedOption.value[index] = option.name;
      selectedOptionIds.value[index] = option.id;
      isDropdownOpen.value[index] = false;
      // Check if option is already in the list
      const optionIndex = selectedOption4.findIndex((op) => option.id === op.id);

      if (optionIndex > -1) {
        selectedOption4.splice(optionIndex, 1); // Remove option if it's already in the list
      } else {
        selectedOption4.push(option); // Add option if it's not in the list
      }

      // Check if option ID is already in the array
      const optionIndex2 = selectedOptionIdsArray4.value.findIndex((op) => option.id === op);

      if (optionIndex2 > -1) {
        selectedOptionIdsArray4.value.splice(optionIndex2, 1); // Remove option ID if it's already in the array
      } else {
        selectedOptionIdsArray4.value.push(option.id); // Add option ID if it's not in the array
      }
    }

    function selectOptionFour(index, option) {
      selectedOption.value[index] = option.name;
      selectedOptionIds.value[index] = option.id;
      isDropdownOpen.value[index] = false;
      // Check if option is already in the list
      const optionIndex = selectedOption5.findIndex((op) => option.id === op.id);

      if (optionIndex > -1) {
        selectedOption5.splice(optionIndex, 1); // Remove option if it's already in the list
      } else {
        selectedOption5.push(option); // Add option if it's not in the list
      }

      // Check if option ID is already in the array
      const optionIndex2 = selectedOptionIdsArray5.value.findIndex((op) => option.id === op);

      if (optionIndex2 > -1) {
        selectedOptionIdsArray5.value.splice(optionIndex2, 1); // Remove option ID if it's already in the array
      } else {
        selectedOptionIdsArray5.value.push(option.id); // Add option ID if it's not in the array
      }
    }

    function selectOptionFive(index, option) {
      selectedOption.value[index] = option.name;
      selectedOptionIds.value[index] = option.id;
      isDropdownOpen.value[index] = false;
      // Check if option is already in the list
      const optionIndex = selectedOption6.findIndex((op) => option.id === op.id);

      if (optionIndex > -1) {
        selectedOption6.splice(optionIndex, 1); // Remove option if it's already in the list
      } else {
        selectedOption6.push(option); // Add option if it's not in the list
      }

      // Check if option ID is already in the array
      const optionIndex2 = selectedOptionIdsArray6.value.findIndex((op) => option.id === op);

      if (optionIndex2 > -1) {
        selectedOptionIdsArray6.value.splice(optionIndex2, 1); // Remove option ID if it's already in the array
      } else {
        selectedOptionIdsArray6.value.push(option.id); // Add option ID if it's not in the array
      }
    }

    function selectOptionSix(index, option) {
      selectedOption.value[index] = option.name;
      selectedOptionIds.value[index] = option.id;
      isDropdownOpen.value[index] = false;
      // Check if option is already in the list
      const optionIndex = selectedOption7.findIndex((op) => option.id === op.id);

      if (optionIndex > -1) {
        selectedOption7.splice(optionIndex, 1); // Remove option if it's already in the list
      } else {
        selectedOption7.push(option); // Add option if it's not in the list
      }

      // Check if option ID is already in the array
      const optionIndex2 = selectedOptionIdsArray7.value.findIndex((op) => option.id === op);

      if (optionIndex2 > -1) {
        selectedOptionIdsArray7.value.splice(optionIndex2, 1); // Remove option ID if it's already in the array
      } else {
        selectedOptionIdsArray7.value.push(option.id); // Add option ID if it's not in the array
      }
    }

    function selectOptionSeven(index, option) {
      selectedOption.value[index] = option.name;
      selectedOptionIds.value[index] = option.id;
      isDropdownOpen.value[index] = false;
      // Check if option is already in the list
      const optionIndex = selectedOption8.findIndex((op) => option.id === op.id);

      if (optionIndex > -1) {
        selectedOption8.splice(optionIndex, 1); // Remove option if it's already in the list
      } else {
        selectedOption8.push(option); // Add option if it's not in the list
      }

      // Check if option ID is already in the array
      const optionIndex2 = selectedOptionIdsArray8.value.findIndex((op) => option.id === op);

      if (optionIndex2 > -1) {
        selectedOptionIdsArray8.value.splice(optionIndex2, 1); // Remove option ID if it's already in the array
      } else {
        selectedOptionIdsArray8.value.push(option.id); // Add option ID if it's not in the array
      }
    }

    function selectOptionEight(index, option) {
      selectedOption.value[index] = option.name;
      selectedOptionIds.value[index] = option.id;
      isDropdownOpen.value[index] = false;
      // Check if option is already in the list
      const optionIndex = selectedOption9.findIndex((op) => option.id === op.id);

      if (optionIndex > -1) {
        selectedOption9.splice(optionIndex, 1); // Remove option if it's already in the list
      } else {
        selectedOption9.push(option); // Add option if it's not in the list
      }

      // Check if option ID is already in the array
      const optionIndex2 = selectedOptionIdsArray9.value.findIndex((op) => option.id === op);

      if (optionIndex2 > -1) {
        selectedOptionIdsArray9.value.splice(optionIndex2, 1); // Remove option ID if it's already in the array
      } else {
        selectedOptionIdsArray9.value.push(option.id); // Add option ID if it's not in the array
      }
    }

    // Close dropdown when clicking outside
    function closeDropdowns(event) {
      const { target } = event; // Element that triggered the event
      const dropdowns = event.currentTarget.querySelectorAll(".custom-select");

      // Check if the target element or its parents have the class name "custom-select"
      const isInsideCustomSelect = Array.from(dropdowns).some((dropdown) =>
        dropdown.contains(target)
      );

      // If not inside custom select, close dropdowns
      if (!isInsideCustomSelect) {
        isDropdownOpen.value = [false, false, false, false];
        activeDropdown = null;
      }
    }

    onMounted(() => {
      // Add event listener to the document for click events
      document.addEventListener("click", closeDropdowns);
    });

    return {
      options: [],
      selectedOption,
      selectedOptionIds,
      isDropdownOpen,
      toggleDropdown,
      selectOption,
      selectOptionOne,
      selectOptionTwo,
      selectOptionThree,
      selectOptionFour,
      selectOptionFive,
      selectOptionSix,
      selectOptionSeven,
      selectOptionEight,
      selectedOption1,
      selectedOption2,
      selectedOption3,
      selectedOption4,
      selectedOption5,
      selectedOption6,
      selectedOption7,
      selectedOption8,
      selectedOption9,
      searchText,
      selectedOptionIdsArray1,
      selectedOptionIdsArray2,
      selectedOptionIdsArray3,
      selectedOptionIdsArray4,
      selectedOptionIdsArray5,
      selectedOptionIdsArray6,
      selectedOptionIdsArray7,
      selectedOptionIdsArray8,
      selectedOptionIdsArray9,
    };
  },
  data() {
    return {
      isActive: false,
      showText: true,
      upholsteries: null,
      legs: null,
      contUpholsteries: null,
      shellColors: null,
      collections: null,
      groups: null,
      subGroups: null,
      categories: null,
      configGroups: null,
      searchKeyword: "",
    };
  },
  mounted() {
    this.fetchDropdownData();
    window.addEventListener("keydown", this.handleKeyDown);
  },
  computed: {
    isSearchDisabled() {
      return (
        this.searchKeyword === "" ||
        this.selectedOption1.length > 0 ||
        this.selectedOption2.length > 0 ||
        this.selectedOption3.length > 0 ||
        this.selectedOption4.length > 0 ||
        this.selectedOption5.length > 0 ||
        this.selectedOption6.length > 0 ||
        this.selectedOption7.length > 0 ||
        this.selectedOption8.length > 0 ||
        this.selectedOption9.length > 0
      );
    },
    filteredUpholsteries() {
      if (this.searchText && this.searchText.trim() !== "") {
        return this.upholsteries.filter(
          (upholstery) =>
            upholstery.name &&
            upholstery.name.toLowerCase().includes(this.searchText.toLocaleLowerCase())
        );
      }
      return this.upholsteries;
    },
    filteredLegs() {
      if (this.searchText && this.searchText.trim() !== "") {
        const filteredList = this.legs.filter(
          (leg) => leg.name && leg.name.toLowerCase().includes(this.searchText.toLocaleLowerCase())
        );
        return filteredList;
      }
      return this.legs;
    },
    filteredContUpholsteries() {
      if (this.searchText && this.searchText.trim() !== "") {
        const filteredList = this.contUpholsteries.filter(
          (contUpholstery) =>
            contUpholstery.name &&
            contUpholstery.name.toLowerCase().includes(this.searchText.toLocaleLowerCase())
        );
        return filteredList;
      }
      return this.contUpholsteries;
    },
    filteredshellColors() {
      if (this.searchText && this.searchText.trim() !== "") {
        const filteredList = this.shellColors.filter(
          (shellColor) =>
            shellColor.name &&
            shellColor.name.toLowerCase().includes(this.searchText.toLocaleLowerCase())
        );
        return filteredList;
      }
      return this.shellColors;
    },
    filteredCollections() {
      if (this.searchText && this.searchText.trim() !== "") {
        const filteredList = this.collections.filter(
          (collection) =>
            collection.name &&
            collection.name.toLowerCase().includes(this.searchText.toLocaleLowerCase())
        );
        return filteredList;
      }
      return this.collections;
    },
    filteredGroups() {
      if (this.searchText && this.searchText.trim() !== "") {
        const filteredList = this.groups.filter(
          (group) =>
            group.name && group.name.toLowerCase().includes(this.searchText.toLocaleLowerCase())
        );
        return filteredList;
      }
      return this.groups;
    },
    filteredSubGroups() {
      if (this.searchText && this.searchText.trim() !== "") {
        const filteredList = this.subGroups.filter(
          (subGroup) =>
            subGroup.name &&
            subGroup.name.toLowerCase().includes(this.searchText.toLocaleLowerCase())
        );
        return filteredList;
      }
      return this.subGroups;
    },
    filteredCategories() {
      if (this.searchText && this.searchText.trim() !== "") {
        const filteredList = this.categories.filter(
          (category) =>
            category.name &&
            category.name.toLowerCase().includes(this.searchText.toLocaleLowerCase())
        );
        return filteredList;
      }
      return this.categories;
    },
    filteredConfiguratorGroups() {
      if (this.searchText && this.searchText.trim() !== "") {
        const filteredList = this.configGroups.filter(
          (configGroup) =>
            configGroup.name &&
            configGroup.name.toLowerCase().includes(this.searchText.toLowerCase())
        );
        return filteredList;
      }
      return this.configGroups;
    },
  },
  methods: {
    toggleActive() {
      this.isActive = !this.isActive;
      this.showText = !this.showText;
    },
    handleInputChange() {
      this.isSearchDisabled = !(
        this.searchKeyword && this.selectedOption.some((option) => option.length > 0)
      );
    },
    async fetchDropdownData() {
      try {
        // Set loading state to true before making the API call
        this.$store.commit("setLoading", true);

        const userId = Cookies.get("user_id");
        const response = await axios.get(`${API_BASE_URL}index/?user_id=${userId}`, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            "X-API-Key": API_KEY,
          },
        });

        this.upholsteries = response.data.upholsteries;
        this.legs = response.data.legs;
        this.contUpholsteries = response.data.piping_and_cushions;
        this.shellColors = response.data.shel_colors;
        this.collections = response.data.collections;
        this.groups = response.data.groups;
        this.subGroups = response.data.sub_groups;
        this.categories = response.data.categories;
        this.configGroups = response.data.config_groups;
        const cartProducts = response.data.cart_products;
        if (cartProducts !== null) {
          this.$store.commit("SET_SELECTED_ITEMS", cartProducts);
        }
        // console.log(response.data.cart_products);
        this.$store.commit("initialProducts", {
          totalData: response.data.search_result.data,
          cart_products: response.data.cart_products,
          total_hits: response.data.search_result.total_hits,
          art_no: response.data.search_result.searchTerm,
          collection_ids: response.data.search_result.collection_ids,
          group_ids: response.data.search_result.group_ids,
          subgroup_ids: response.data.search_result.subgroup_ids,
          category_ids: response.data.search_result.category_ids,
          config_groups: response.data.search_result.config_groups,
          shell_colors: response.data.search_result.shell_colors,
          leg_colors: response.data.search_result.leg_colors,
          upholsteries: response.data.search_result.upholsteries,
          cont_upholsteries: response.data.search_result.cont_upholsteries,
          items_per_page: response.data.search_result.productPerPage,
          page: response.data.search_result.selectedPage,
        });

        // Set loading state to false after content is loaded
        this.$store.commit("setLoading", false);
      } catch (error) {
        console.error(error);
        // Set loading state to false in case of an error
        this.$store.commit("setLoading", false);
      }
    },

    async fetchData() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.commit("clearOnFilter");
      this.$store.commit("setSearchClicked", true);
      this.$store.commit("setLoading", true); // Set loading to true before content loading
      this.$root.$emit("search-clicked");

      this.$store.commit("setLoading", true); // Set loading to true before content loading

      const { selected } = this.$store.state.productState.selected;
      let filter = "";

      if (selected === "Published items") {
        filter = "published";
      } else if (selected === "Unpublished items") {
        filter = "sent";
      } else if (selected === "All items") {
        filter = "";
      }
      // console.log('test1', this.selectedOptionIdsArray1);
      // console.log('test2', this.selectedOptionIdsArray2);
      // console.log('test3', this.selectedOptionIdsArray3);
      // console.log('test4', this.selectedOptionIdsArray4);
      // console.log('test5', this.selectedOptionIdsArray5);
      // console.log('test6', this.selectedOptionIdsArray6);
      // console.log('test7', this.selectedOptionIdsArray7);
      // console.log('test8', this.selectedOptionIdsArray8);
      // console.log('test9', this.selectedOptionIdsArray9);
      try {
        await this.$store.dispatch("productInitialList", {
          searchKeyword: this.searchKeyword,
          selectOptionZero:
            this.selectedOptionIdsArray1 !== null ? this.selectedOptionIdsArray1 : [],
          selectOptionOne:
            this.selectedOptionIdsArray2 !== null ? this.selectedOptionIdsArray2 : [],
          selectOptionTwo:
            this.selectedOptionIdsArray3 !== null ? this.selectedOptionIdsArray3 : [],
          selectOptionThree:
            this.selectedOptionIdsArray4 !== null ? this.selectedOptionIdsArray4 : [],
          selectOptionFour:
            this.selectedOptionIdsArray5 !== null ? this.selectedOptionIdsArray5 : [],
          selectOptionFive:
            this.selectedOptionIdsArray6 !== null ? this.selectedOptionIdsArray6 : [],
          selectOptionSix:
            this.selectedOptionIdsArray7 !== null ? this.selectedOptionIdsArray7 : [],
          selectOptionSeven:
            this.selectedOptionIdsArray8 !== null ? this.selectedOptionIdsArray8 : [],
          selectOptionEight:
            this.selectedOptionIdsArray9 !== null ? this.selectedOptionIdsArray9 : [],
          selectedPage: 1,
          filter,
        });

        this.$store.commit("pageChangeFeature", 1);

        this.$store.commit("setLoading", false); // Set loading to false after content loading
        this.$root.$emit("search-completed");
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
        this.$store.commit("setLoading", false); // Make sure loading is set to false in case of an error
      }
    },
    handleKeyDown() {
      if (this.searchKeyword === "") {
        this.$store.commit("clearArtNo");
      }
    },
    clearFilter() {
      // console.log('component');
      this.searchKeyword = "";
      this.$store.commit("clearArtNo");
      this.selectedOption1.splice(0, this.selectedOption1.length);
      this.selectedOption2.splice(0, this.selectedOption2.length);
      this.selectedOption3.splice(0, this.selectedOption3.length);
      this.selectedOption4.splice(0, this.selectedOption4.length);
      this.selectedOption5.splice(0, this.selectedOption5.length);
      this.selectedOption6.splice(0, this.selectedOption6.length);
      this.selectedOption7.splice(0, this.selectedOption7.length);
      this.selectedOption8.splice(0, this.selectedOption8.length);
      this.selectedOption9.splice(0, this.selectedOption9.length);
      // this.selectedOptionIds = ref(['', '', '', '', '', '', '', '', '']);
      this.selectedOptionIdsArray1.splice(0, this.selectedOptionIdsArray1.length);
      this.selectedOptionIdsArray2.splice(0, this.selectedOptionIdsArray2.length);
      this.selectedOptionIdsArray3.splice(0, this.selectedOptionIdsArray3.length);
      this.selectedOptionIdsArray4.splice(0, this.selectedOptionIdsArray4.length);
      this.selectedOptionIdsArray5.splice(0, this.selectedOptionIdsArray5.length);
      this.selectedOptionIdsArray6.splice(0, this.selectedOptionIdsArray6.length);
      this.selectedOptionIdsArray7.splice(0, this.selectedOptionIdsArray7.length);
      this.selectedOptionIdsArray8.splice(0, this.selectedOptionIdsArray8.length);
      this.selectedOptionIdsArray9.splice(0, this.selectedOptionIdsArray9.length);
    },
    clearSelectionOne() {
      this.selectedOption1.splice(0, this.selectedOption1.length);
      this.selectedOptionIdsArray1.splice(0, this.selectedOptionIdsArray1.length);
    },
    clearSelectionTwo() {
      this.selectedOption2.splice(0, this.selectedOption2.length);
      this.selectedOptionIdsArray2.splice(0, this.selectedOptionIdsArray2.length);
    },
    clearSelectionThree() {
      this.selectedOption3.splice(0, this.selectedOption3.length);
      this.selectedOptionIdsArray3.splice(0, this.selectedOptionIdsArray3.length);
    },
    clearSelectionFour() {
      this.selectedOption4.splice(0, this.selectedOption4.length);
      this.selectedOptionIdsArray4.splice(0, this.selectedOptionIdsArray4.length);
    },
    clearSelectionFive() {
      this.selectedOption5.splice(0, this.selectedOption5.length);
      this.selectedOptionIdsArray5.splice(0, this.selectedOptionIdsArray5.length);
    },
    clearSelectionSix() {
      this.selectedOption6.splice(0, this.selectedOption6.length);
      this.selectedOptionIdsArray6.splice(0, this.selectedOptionIdsArray6.length);
    },
    clearSelectionSeven() {
      this.selectedOption7.splice(0, this.selectedOption7.length);
      this.selectedOptionIdsArray7.splice(0, this.selectedOptionIdsArray7.length);
    },
    clearSelectionEight() {
      this.selectedOption8.splice(0, this.selectedOption8.length);
      this.selectedOptionIdsArray8.splice(0, this.selectedOptionIdsArray8.length);
    },
    clearSelectionNine() {
      this.selectedOption9.splice(0, this.selectedOption9.length);
      this.selectedOptionIdsArray9.splice(0, this.selectedOptionIdsArray9.length);
    },
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },
};
</script>

<style lang="scss">
.product-search {
  background: #ebebeb;
  position: relative;
}

.dashboard .advanced-search-wrap {
  margin: 0px !important;
  margin: 0px;
  transition: max-height 0.8s ease, opacity 0.8s ease;
  overflow: initial;
  opacity: 0;
  width: 100%;
  max-height: 0px;
  display: block !important;
}

.inner {
  // margin: 32px 0 77px;
  margin: 32px 0 0px;
  width: 100%;
  max-width: 100%;
}

.advanced-search-wrap.active {
  max-height: 500px;
  opacity: 1;
  transition: 1s ease;
  // overflow: initial;
}

.dashboard {
  min-height: calc(100vh - 100px);
  display: flex;
  padding-top: 100px;

  &-wrap {
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    .filter-wrap {
      padding: 40px 62px 40px 0;
      position: -webkit-sticky;
      position: sticky;
      top: 100px;
      max-height: calc(100vh - 100px);
      overflow: auto;
      height: 100%;

      @media (max-width: 1366px) {
        padding: 40px 15px 40px 0;
      }

      @media (max-width: 1024px) {
        padding: 30px 0;
        max-height: none;
        height: auto;
      }
    }

    .left-side {
      max-width: 395px;
      width: 100%;
      background: #ebebeb;
      position: relative;

      @media (max-width: 1366px) {
        max-width: 325px;
      }

      @media (max-width: 1024px) {
        max-width: 100%;
        z-index: 9;
      }

      &:after {
        position: absolute;
        content: "";
        width: 1000%;
        height: 100%;
        right: 100%;
        top: 0;
        background: #ebebeb;
      }

      &:before {
        position: absolute;
        content: "";
        width: 15px;
        height: 100%;
        left: 100%;
        top: 0;
        background: #ebebeb;
        display: none;

        @media (max-width: 1024px) {
          display: block;
        }
      }

      input {
        border: 0.3px solid #a1a1a1;
        width: 100%;
        height: 44px;
        background-color: transparent;
        padding: 0 27px;
        outline: 0;

        &::placeholder {
          font-weight: 300;
          font-size: 13px;
          line-height: 15px;
          color: #c1c1c1;
          font-family: "gill_sans_mtregular";
        }
      }

      label {
        position: relative;
        display: block;
      }

      .label-name {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #000000;
        position: absolute;
        left: 20px;
        top: -10px;
        background: #ebebeb;
        padding: 0 0 0 8px;
      }
    }

    .right-side {
      width: calc(100% - 395px);
      padding-bottom: 2px;

      @media (max-width: 1366px) {
        width: calc(100% - 325px);
      }

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  .advanced-click {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #073bf4;
    text-decoration: underline;
    margin: 6px 0 0;
    text-align: right;
    cursor: pointer;

    &.active {
      // color: #906363;
    }
  }

  .advanced-search {
    margin-bottom: 32px;
    display: flex;
    width: 100%;
    width: 100%;
    justify-content: flex-end;
    flex-wrap: Wrap;

    &-wrap {
      margin: 32px 0 77px;
      // display: none;

      &.active {
        display: block;
      }
    }
  }

  .custom-select {
    position: relative;
    width: 100%;
    margin-bottom: 27px;

    .select-box {
      position: relative;
      cursor: pointer;
      padding: 12px 29px;
      padding-right: 35px;
      border: 0.3px solid #a1a1a1;
    }

    .selected-item {
      display: inline-block;
      font-weight: 300;
      font-size: 13px;
      line-height: 15px;
      color: #c1c1c1;
      font-family: "gill_sans_mtregular";

      &.list-selected {
        color: #000;
      }
    }

    .arrow {
      position: absolute;
      top: 50%;
      right: 10px;
      width: 7.4px;
      height: 7.4px;
      border-right: 1px solid #000;
      border-top: 1px solid #000;
      transform: translateY(-50%) rotate(135deg);
      transition: transform 0.3s;
    }

    .arrow.open {
      transform: translateY(-50%) rotate(315deg);
    }

    .close-button {
      position: absolute;
      right: 28px;
      top: 57%;
      transform: translateY(-50%);
      font-size: 12px;
      cursor: pointer;
      padding: 0 3px;
    }

    .dropdown-menu {
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      width: 100%;
      padding: 0 0;
      margin-top: 4px;
      background-color: #fff;
      border-radius: 0;
      list-style: none;
      transform: translateY(-10px);
      transition: opacity 0.3s, transform 0.3s;
      z-index: 9;
      border: 0.3px solid #d8d8d8;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      max-height: 193px;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 99;

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 0px;
        background-color: transparent;
        border: 1px solid #e8e8e8;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #7a7a7a;
      }
    }

    .dropdown-menu li {
      padding: 5.5px 10px;
      cursor: pointer;
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      color: #8d8d8d;
      transition: all 0.3s ease;
      border-bottom: 0.3px solid #e9e9e9;
      margin: 0;

      &:hover {
        background-color: #000000;
        color: #ffffff;
      }
    }

    .dropdown-menu.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  .btn-wrap {
    width: 100%;
    margin-top: -5px;
    z-index: 9;
    position: relative;

    button {
      width: 100%;
      cursor: pointer;
      min-height: 50px;
      font-family: "gill_sans_mtbold";

      &[disabled] {
        pointer-events: none;
        opacity: 0.7;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.7;
      }
    }
  }
}

.dropdown-menu li.placeholder {
  display: none;
}

.clear-filter {
  text-align: right;
  margin-bottom: 9px;

  button {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #e85353;
    background-color: transparent;
    border: 0;
    padding: 0;
    font-family: "gill_sans_mtregular";
    cursor: pointer;
  }
}

.dropdown-search {
  background-color: #fff;
}

.dashboard .sort .custom-select .dropdown-menu li {
  color: #060606;
  font-size: 13px;
}

.dashboard .sort .custom-select .dropdown-menu li:hover {
  color: #fff;
}
</style>
